import React from 'react';
import HeaderImg from '../components/headerImg/HeaderImg';
import zimVieHeader from '../Images/zimVieHeader.jpg';
import TrainingPortals from '../components/trainingPortals/TrainingPortals';

const portals = [{
    center: 'Oral & Maxillofacial Surgeons',
    brandimg: '/images/oralBrandImg.jpg',
    ctatext: 'Visit the OS Dental Implant Mini-ReZidency powered by Oral & Maxillofacial',
    url: 'https://axiom.betterlx.com/local/iomad_signup/login.php?id=63&code=Beacon'
}
]


function Newsfeed() {

    return (
        <div>
            <HeaderImg
                img={zimVieHeader}
                header={'Check out these World Class Mini-ReZidency Training Portals'}
            />
            {portals.map((portal, i) => {
                return (<TrainingPortals key={i}
                    center={portal.center}
                    brandimg={portal.brandimg}
                    ctatext={portal.ctatext}
                    url={portal.url}
                    isOdd={i%2 === 1}
                />)
            })}
        </div>
    )
}

export default Newsfeed
