import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/navbar/Navbar"
import Home from "./pages/Home/Home";
import Footer from './components/footer/Footer'
import SurgicalCenter from "./pages/SurgicalCenter"

function App() {

  return (
    
      <div className="App">
        <Router>
          <div>
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
            </Switch>
            <Switch>
              <Route exact path="/surgical-center" component={SurgicalCenter} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </div>

  );
}

export default App;