import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom"
import './Navbar.css';
import zimVielogo from '../../Images/zimVieLogo.jpg';

const linkData = [
    {
        currUrl: '/',
        url: '/surgical-center',
        name: 'Surgical Center'

    }, {
        currUrl: '/surgical-center',
        url: '/',
        name: 'Home'
    }
]

function Navbar() {
    const location = useLocation()

    const [selectedLink, setSelectedLink] = useState({ url: '', name: '' })

    useEffect(() => {
        if (location.pathname) {
            let data = linkData.find(e => e.currUrl === location.pathname)
            setSelectedLink({ ...data })
        }
    }, [location])

    return (
        <div>
            <div id='navHolder'>
                <div id='navContainer'>
                    <div id='leftNav'>
                        <div className='logoContainer'>
                            <img src={zimVielogo} alt="ZimVie Logo" />
                        </div>
                    </div>
                    <div id='rightNav'>
                        <a href={selectedLink.url} className='noSelect' id='linkContainer'>{selectedLink.name}</a>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Navbar