import React, { useState } from 'react';
import './Home.css';
import '../../index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css"
import HeaderImg from '../../components/headerImg/HeaderImg';
import zimVieHeader from '../../Images/zimVieHeader.jpg';
import SpeakerBanner from '../../components/speakerBanner/SpeakerBanner';
import SpeakerCard from '../../components/speakerCard/SpeakerCard';
import Modal from '../../components/modal/Modal';

import useWindowDimensions from '../../hooks/UseWindowDimensions'

SwiperCore.use([Pagination, Navigation, Autoplay]);

const speakers = [
    {
        name: 'Sompop Bencharit,',
        accolades: 'DDS, MSc, PhD, FACP',
        img: '/images/bencharSompop.png',
        profile: 'Dr. Bencharit is an Associate Professor and the Director of Digital Dentistry Technologies in the Department of General Practice at the Virginia Commonwealth University (VCU) School of Dentistry; and the Department of Biomedical Engineering at the VCU College of Engineering. He received his DDS and Diploma in Prosthodontics from Chulalongkorn University, Bangkok, Thailand. Dr. Bencharit earned a Certificate and Master Degree in Prosthodontics, a PhD in Oral Biology, and a Certificate in Clinical Research from the University of North Carolina at Chapel Hill. He is a Diplomate of the American Board of Prosthodontics and a Fellow of the American College of Prosthodontists. Dr. Bencharit established the Virginian Commonwealth University Center of Digital Dentistry and the pre-doctoral CAD/CAM Dentistry Curriculum and Clinic. He limits his clinical practice to digital dentistry, dental implants, prosthodontics, and esthetic dentistry. Dr. Bencharit published more than 60 peer-review articles and 100 abstracts. He lectures nationally and internationally on various topics from 3D printing and guided implant surgery to digital dentistry and more.',
        specialty: 'Prosthodontist',
        location: 'Virginia'
    },
    {
        name: 'Julian Conejo,',
        accolades: 'DDS, MSc',
        img: '/images/julianConejo.png',
        profile: 'Dr. Julian Conejo is the Director of Clinical CAD/CAM Technologies in the Department of Preventive and Restorative Sciences at the University of Pennsylvania School of Dental Medicine in Philadelphia, Pennsylvania. He obtained his dental degree from the Universidad Latina, Costa Rica and then completed training as a prosthodontist at Universidad Intercontinental, Mexico. Subsequently, he was appointed as a Professor at the Prosthodontics Department, Universidad Latina. At Penn Dental Medicine, he conducts research on CAD/CAM technology, prosthodontics, and implant dentistry. Dr. Conejo was awarded the Young Clinician Award in 2008 and the ICP Research Fellowship in Dental Restorative Materials in 2016. He has lectured in more than 30 countries focusing on digital workflows for esthetic tooth and implant-supported restorations',
        specialty: 'Prosthodontist',
        location: 'Pennsylvania'
    },
    {
        name: 'Brian Bray,',
        accolades: 'DMD',
        img: '/images/brianBray.png',
        profile: 'Dr. Bray is a graduate of Texas Christian University, earning a BS in Speech-Language Pathology and General Sciences and a master’s degree in Speech-Language Pathology. After practicing speech pathology in Portland, Oregon for several years he attended dental school and graduated from Oregon Health Sciences University. His interests in removable, fixed and implant prosthetics led him to Nova Southeastern University in Fort Lauderdale, Florida where he completed a three year Prosthodontic residency. Dr. Bray is currently a member of the Multnomah, Oregon, and American Dental Associations, the American College of Prosthodontics, the Academy of Osseointegration, International Team for Implantology, and the American speech-Language Hearing Association. Dr. Bray maintains a private practice specializing in removable, fixed, and implant Prosthodontics in Portland, Oregon.',
        specialty: 'Prosthodontist',
        location: 'Oregon'
    },
    {
        name: 'Geoffrey R. Cunningham,',
        accolades: 'DDS, MS, FACP',
        img: '/images/geoffreyCunningham.png',
        profile: 'Dr. Cunningham received a dental degree and Master of Science in Prosthodontics from West Virginia University in Morgantown, West Virginia. He is a Diplomate with the American Board of Prosthodontics and is the current owner and president of a prosthodontics practice in Durham, North Carolina. His practice focuses on reconstructive dentistry where he typically treats patients requiring complex multidisciplinary rehabilitation involving implant, fixed, and removable prosthodontics. Dr. Cunningham lectures nationally and is an adjunct professor at the University of North Carolina at Chapel Hill.',
        specialty: 'Prosthodontist',
        location: 'North Carolina'
    },
    {
        name: 'Wael Garine,',
        accolades: 'DDS',
        img: '/images/garineWael.png',
        profile: 'Dr. Garine graduated from Cairo University School of Dentistry in Egypt. He joined the Dental School at the University of Western Ontario, in London, Ontario, where he earned his dental degree. Graduating first in his class, Dr. Garine received the Dr. Gerald Z. Wright Award for excellence. He then joined the Eastman Dental Center at the University of Rochester in NY where he spent three years specializing in the area of Prosthodontics. Dr. Garine spent the following year teaching at the University of Rochester and completed the Implant Fellowship for which he received the Dr. Gerald N. Graser Fellowship Award. Dr. Garine’s research in implant dentistry has received several awards and was published in the International Journal of Oral and Maxillofacial Implants. He is the Director of the Seaside Study Club, and a clinical assistant professor at the University of Rochester, Rochester, NY. Dr. Garine maintains a private practice, limited to prosthodontics and implant dentistry in Jupiter, Florida.',
        specialty: 'Prosthodontist',
        location: 'Florida'
    },
    {
        name: 'Furat M. George,',
        accolades: 'BDS, MS',
        img: '/images/furatGeorge.png',
        profile: 'Dr. George received his dental degree from the University of Baghdad, College of Dentistry and his prosthodontic specialty training at the University of Michigan, School of Dentistry. Dr. George is currently a Clinical Associate Professor in the department of Biologic and Materials Sciences and Prosthodontics, Co-director of the Pre-doctoral Implant Program and Director of the Graduate Prosthodontics Clinic. He is a member of the American College of Prosthodontists, a member of the International Association for Dental Research and the American Association for Dental Research, and a Fellow of the International Congress of Oral Implantologists. His research interests are in the field of dental ceramics and the utilization of CAD/CAM technology in dentistry and research. Dr. George serves as a reviewer for multiple scientific journals and has multiple publications in the field of prosthodontics and dental implants. His private practice is limited to prosthodontics and implant dentistry.',
        specialty: 'Prosthodontist',
        location: 'Michigan'
    },
    {
        name: 'Roya Zandparsa,',
        accolades: 'DDS, MSc, DMD',
        img: '/images/royaZandparsa.png',
        profile: 'Dr. Zandparsa is a Professor and Biomaterials Course Director of the Department of Prosthodontics at Tufts University School of Dental Medicine and the President of American Academy of Dental Science. She received her DMD from Tufts University School of Dental Medicine, an MSc in Biomaterials & Biomimetics and Certificate in Prosthodontics from New York University (NYU) Graduate School of Arts and Science and New York University College of Dentistry, respectively. She completed a fellowship in Implant Dentistry from Harvard University School of Dental Medicine and a fellowship in Academic Leadership from American Dental Education Association (ADEA) Leadership Institute. Dr. Zandparsa has received numerous awards during her academic career in Prosthodontics for being an outstanding scholar & clinician from NYU College of Dentistry, and Deans’ Awards for Excellence in clinical teaching from Tufts University School of Dental Medicine. She has been making major contributions in research, which has resulted in numerous published articles and book chapters. Dr. Zandparsa lectures nationally and internationally and reviews manuscripts for several scientific journals.',
        specialty: 'Prosthodontist',
        location: 'Massachusetts'
    },
    {
        name: 'Bruce Ouellette,',
        accolades: 'DDS',
        img: '/images/bruceOuellette.jpg',
        profile: 'Dr. Ouellette received his dental degree from the University of Maryland in Baltimore, Maryland. His professional affiliations include the American Academy of Cosmetic Dentistry, American Dental Association, Florida Academy of Cosmetic Dentistry, American Equilibration Society and the Florida Dental Association. Dr. Ouellette is a former faculty member at the Dawson Academy For Advanced Dental Study in St. Petersburg, Florida, a former Clinical Instructor at Palm Beach State College and Visiting Faculty for advanced continuing dental education at Baylor College of Dentistry in Dallas, Texas. Dr. Ouellette maintains a full-time private practice, focusing on occlusion, aesthetics, implant reconstruction and TMJ in West Palm Beach, Florida.',
        specialty: 'Prosthodontist',
        location: 'Florida'
    },
    {
        name: 'Jay Smith,',
        accolades: 'DDS',
        img: '/images/jaySmith.png',
        profile: 'Dr. Smith earned his Bachelor of Science in Biology at Furman University in Greenville, South Carolina and received his dental degree and completed his residency in fixed prosthodontics at Emory University. He was awarded the Hinman Scholar Award for Scholastic Achievement. Dr. Smith is president of the Western Georgia section of the American College of Prosthodontists and a member of the Academy of Osseointegration, the American Dental Association and the Georgia Dental Association. He regularly lectures both nationally and internationally on topics including implant, esthetic and reconstructive dentistry. He maintains a private practice, limited to prosthodontics, in Atlanta, Georgia.',
        specialty: 'Prosthodontist',
        location: 'Georgia'
    },
    {
        name: 'Roberto Macedo,',
        accolades: 'DDS, MS',
        img: '/images/robertoMacedo.png',
        profile: 'Dr. Macedo received his dental degree from Paulista University, School of Dentistry in São Paulo, Brazil and his MS and PhD from the University of São Paulo School of Dentistry in São Paulo, Brazil and completed a Prosthodontic Residency at UAB Dental School and Veterans Affairs Medical Center in Birmingham, AL. After earning his DDS, MS, Ph.D., and becoming a prosthodontist, Dr. Macedo taught for three years at Oregon Health and Science University School of Dentistry as a professor. Since 2002, he has lectured extensively on the topics of dental implants, cosmetic dentistry, advanced restorative dentistry, aesthetic analysis, minimally invasive dentistry, and more. He was able to continue to be involved in hand-on dentistry while pursuing his academics, which has enabled Dr. Macedo to excel when working with difficult and complex restorations. He has the additional advantage of having worked in a dental lab before dental school, which helps him with the aesthetic restorations he provides and gives him a deep understanding of exactly what is involved for optimum function. Dr. Macedo maintains a private practice in Saint Petersburg, FL.',
        specialty: 'Prosthodontist',
        location: 'Florida'
    },
    {
        name: 'Ed McGlumphy,',
        accolades: 'DDS, MS',
        img: '/images/edMcGlumphy.png',
        profile: 'Dr. McGlumphy received his dental degree, as well as Certificates in Advanced Prosthodontics from The Ohio State University, College of Dentistry, Columbus, Ohio, where he is currently the Professor Emeritus of Restorative and Prosthetic Dentistry. He is a referee for the Journal of Prosthetic Dentistry and has published many articles on implant dentistry. From 1988 until 2019, Dr. McGlumphy maintained an intramural faculty practice at The Ohio State University College of Dentistry, with an emphasis on fixed, removable, and implant prosthodontics. He is currently Clinical Director at the Columbus, Ohio ClearChoice Dental Implant Center with a focus on full-arch, immediate load implant-supported restorations.',
        specialty: 'Prosthodontist',
        location: 'Ohio'
    },
    {
        name: 'Steven J. LoCascio,',
        accolades: 'BS, DDS',
        img: '/images/stevenLocascio.png',
        profile: 'Dr. LoCascio received his dental degree from Louisiana State University School of Dentistry in New Orleans and completed a General Practice Residency at the Medical Center of Louisiana, Charity Hospital. He returned to the School of Dentistry at Louisiana State University to pursue advanced training in restorative dentistry and was awarded specialty certificates in Prosthodontics and Maxillofacial Prosthetics. He is a member of the American Dental Association, the American College of Prosthodontists, the Academy of Osseointegration and the Second District Dental Society. Dr. LoCascio is a Clinical Associate Professor in the Department of Oral and Maxillofacial Surgery at the University of Tennessee Medical Center in Knoxville, Tennessee. He is a Clinical Assistant Professor in the Department of Prosthodontics at Louisiana State University School of Dentistry in New Orleans, Louisiana. Dr. LoCascio maintains a private practice limited to prosthodontics and maxillofacial prosthetics in Knoxville, Tennessee.',
        specialty: 'Prosthodontist',
        location: 'Tennessee'
    },
    {
        name: 'Jon Ruel Sr.,',
        accolades: 'DMD, MSCD',
        img: '/images/jonRuel.png',
        profile: 'Dr. Ruel received his dental degree from the University of Pennsylvania, School of Dentistry and his Certificate in Prosthetics and Masters of Dental Science from Boston University School of Graduate Dentistry. He is a member of the Academy of Osseointegration and American College of Prosthodontists. Dr. Ruel lectures extensively on prosthetics and implant dentistry and maintains a private practice limited to prosthetic and implant dentistry in New Bedford, Massachusetts.',
        specialty: 'Prosthodontist',
        location: 'Massachusetts'
    },
    {
        name: 'Gary Morris,',
        accolades: 'DDS',
        img: '/images/garyMorris.png',
        profile: 'Dr. Morris completed his undergraduate studies at Indiana University in Bloomington, Indiana. He received his dental degree from the University of Illinois at Chicago and completed a residency in Prosthodontics at UMKC College of Dentistry in Kansas City, Missouri. He is an Adjunct Clinical Assistant Professor at Southern Illinois University, School of Dental Medicine and was a Clinical Assistant Professor at the University of Illinois, College of Dentistry. Dr. Morris is the President of the Illinois Section of the American College of Prosthodontists, the past president of the Chicago Academy of Dental Research, and a member of several professional associations. He maintains a private practice limited to prosthodontics and restorative dentistry in Buffalo Grove, Illinois.',
        specialty: 'Prosthodontist',
        location: 'Illinois'
    }
]


function Home() {
    const { width } = useWindowDimensions();

    const [modalSelected, setModalSelected] = useState(-1)


    return (
        <div>
            {modalSelected !== -1 &&
                <Modal
                    data={speakers[modalSelected]}
                    closeModal={() => { setModalSelected(-1) }}
                />
            }
            <HeaderImg
                img={zimVieHeader}
                header={'A More Educated Tomorrow'}
                sub={'Continue your personal, practice, and team development by joining one of these premier education programs'}
                cta={'Enter Here'}
                buttonLink={'/surgical-center'}
            />
            <SpeakerBanner />
            <div id='cardBannerHolder'>
                <div id='cardBannerContainer'>
                    <Swiper
                        modules={[Pagination, Navigation, Autoplay]}
                        speed= {2500}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                          }}
                        slidesPerView={ width > 890 ? 3 : width > 450 ? 2 : 1 }
                        centeredSlides={false}
                        loop={true}
                        navigation={true}
                        pagination={{ clickable: true }}
                    >
                        {speakers.map((speaker, i) => {
                            return (
                                <SwiperSlide key={i} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <SpeakerCard
                                        img={speaker.img}
                                        name={speaker.name}
                                        accolades={speaker.accolades}
                                        cardSelect={() => { setModalSelected(i) }}
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </div>

    )
}

export default Home
