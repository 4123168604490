import React from 'react'
import './Footer.css'
import zimVielogo from '../../Images/zimVieLogo.jpg';

function Footer() {
    return (
        <div id='footerHolder'>
            <div id='footerCenter'>
                <div id='footerLinks'>
                    <div id='initialSpace'></div>
                    <a className='specificLink' href="https://www.zimvie.com/Legal-Global-Privacy-Policy">Privacy Notice</a>
                    <p className='dot'>&#x2022;</p>
                    <a className='specificLink' href="https://www.zimvie.com/en/legal-notice.html">Terms of Use</a>
                    <p className='dot'>&#x2022;</p>
                    <a className='specificLink' href="https://www.zimvie.com/en/legal-notice/uk-modern-slavery-act-and-california-transparency-in-supply-chai.html">California &amp; Transparency in Supply Chain Act</a>
                </div>
                <div id='footerBottom'>
                    <img id='footerIcon' src={zimVielogo} />
                    <p id='crSpace'>Copyright 2023 ZimVie Inc.</p>
             </div>
            </div>
        </div>
    )
}

export default Footer