import React from 'react'
import './TrainingPortals.css'

function TrainingPortals({ center, brandimg, ctatext, url, isOdd }) {
    return (
        <div className={isOdd ? 'trainingHolder backgroundGrey' : 'trainingHolder'}>
            <div className='trainingContainer'>
                <div className='brandImageContainer'>
                    <img className={isOdd ? 'veroImage' : 'brandImage'} src={brandimg} />
                </div>
                <div className='rightContainer'>
                    <p className='brandTitle'>{center}</p>
                    <div className='brandCTA'>{ctatext}</div>
                    <div className='portalButton'>
                        <a href={url}>Enter Here</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrainingPortals