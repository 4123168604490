import React from "react";
import './SpeakerCard.css'

function SpeakerCard({ img, name, accolades, cardSelect }) {
    return (
        <div className='cardContainer'>
            <div style={{backgroundImage: `url(${img})`}} className="speakerImgContainer">
                {/* <img className='speakerImg' src={img} /> */}
            </div>
            <div className="cardRight">
                <p className="cardText">{name}</p>
                <p className="cardText">{accolades}</p>
                <div onClick={cardSelect} className="cardButton">
                    <p>About</p>
                </div>
            </div>
        </div>
    );
}

export default SpeakerCard 