import React from 'react'

import './Modal.css'

function Modal({closeModal, data}) {

  const handleModalClose = (e) => {
    if (e.target.className === 'modalOverlayContainer' || e.target.className === 'closeModal') {
      closeModal()
    }

  }

  return (
    <div className='modalOverlayContainer' onClick={handleModalClose} >
      <div className='modalContainer'>
        <img className='closeModal' src='/images/close.png'></img>
        <img className='modalDoctorImg' src={data.img}/>
        <h1 className='headingFont'>{data.name}</h1>
        <h2 className='subHeadingFont'>Specialty: <span className='subHeadingValue'>{data.specialty}</span></h2>
        <h2 style={{marginBottom: '20px'}} className='subHeadingFont'>Location:  <span className='subHeadingValue'>{data.location}</span></h2>
        <p>{data.profile}</p>
      </div>
    </div>
  )
}

export default Modal