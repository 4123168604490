import React from 'react'
import './SpeakerBanner.css'
import speakerIcon from '../../Images/speakerIcon.png'


function SpeakerBanner() {
  return (
    <div id='speakerHolder'>
        <div id='speakerContainer'>
            <div id='speakerLeft'>
                <img id='speakerIcon' src={speakerIcon}/>
                <div id='speakerText'>
                    <div id='speakerTitle'>
                        <p id='speakerT1'>The</p>
                        <p id='speakerT2'>Speakers</p>
                    </div>
                    <div id='speakerSub'>
                        <p>We brought together a group of people that have devoted their time and energy into progressing dentistry</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SpeakerBanner