import React from 'react';
import './HeaderImg.css';

function HeaderImg({ img, header, sub, cta, buttonLink }) {
    return (
        <div id='headerHolder'>
            <div id='headerContainer'>
                <img className="headerImageStyle" src={img} />
                <div className="headerContentDiv">
                    <h1 id='headerTitle'>{header}</h1>
                    {sub &&
                        <p id='headerSub'>{sub}</p>
                    }
                    {cta &&
                        <div id='headerCTA'>
                            <a href={buttonLink}>{cta}</a>
                        </div>
                    }
                </div>
            </div>

        </div>
    );
}

export default HeaderImg